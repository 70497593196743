import type { IronSessionOptions } from "iron-session";
import type { User, ShareUser } from "../../types";
import { getIronSession } from "iron-session";

export const sessionOptions: IronSessionOptions = {
  password: process.env.COOKIE_SESSION_SECRET as string,
  cookieName: process.env.COOKIE_SESSION_NAME as string,
  ttl: 3600,
  cookieOptions: {
    secure: process.env.NODE_ENV === "production",
    maxAge: 3540,
  },
};

export const shareSessionOptions: IronSessionOptions = {
  password: process.env.SHARE_SESSION_SECRET as string,
  cookieName: process.env.SHARE_SESSION_NAME as string,
  cookieOptions: {
    secure: process.env.NODE_ENV === "production",
  },
};

declare module "iron-session" {
  interface IronSessionData {
    user?: User;
    share?: ShareUser;
  }
}

const getUser = async (req, res) => {
  if (req && res) {
    const { user } = await getIronSession(req, res, sessionOptions);
    return user;
  }
  return null;
};

export { getUser };
